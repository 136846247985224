import React, { useState, useEffect } from 'react';
import Api from '../../services/api';
import { withRouter } from "react-router";
import { useToasts } from 'react-toast-notifications';
import Swal from "sweetalert2";
import { Link } from 'react-router-dom';

import LoadingOverlay from 'react-loading-overlay';

import DataTable from 'react-data-table-component';

const Painel = () => {
 
  //Atualização
  const { addToast } = useToasts();
  const [ countExames , setCountExames ] = useState([]);
  const [ caGrafico, setCaGrafico ] = useState(false);
  const [ caGraficoTipo, setCaGraficoTipo ] = useState(false);
  const [ caGraficoAnuidade, setCaGraficoAnuidade ] = useState(false);
  const [ caPainel, setCaPainel ] = useState(false);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ exames, setExames ] = useState([]);

  const data = exames;
  const columns = [
    {
      name: <th>Paciente</th>,
      selector: 'PACNOM',
      sortable: true,
      wrap: true,
      width: '28%',
    },
    {
      name: <th>Médico</th>,
      selector: 'MESNOM',
      width: '28%',
      sortable: true,
    },
    {
      name: <th>Data</th>,
      selector: 'ATEDAT',
      width: '12%',
      sortable: true,
    },
    {
      name: <th>Nome do exame</th>,
      width: '20%',
      sortable: true,
      selector: 'SERDES',
    }, 
    {
      name: <th>Ações</th>,
      center: 'true',
      cell: (row) => 
      { return (<>
        <button className="btn btn-success btn-sm" onClick={e => { abrirLaudo(row) }}><i className="far fa-folder-open" />Abrir</button>
    </>)
      },
    },
  ];

  useEffect(() => {
    painel();
    getListar();
  }, []);

  function painel(){
    var data = {
      pac: localStorage.getItem('id_usuario'),
    };

    Api.post("painel/countexames", data).then(rps => {
      setCountExames(rps.data.obj.qtde);
    });
  }

  function getListar() { 
    setCaProcessa(true); 
    var data_atual = new Date();
    var data = {
      pac: localStorage.getItem('id_usuario'),
      dt_inicial: new Date(new Date().setDate(data_atual.getDate() - 30)),
      dt_final: data_atual,
    };
  
    Api.post('laudos/filtrar', data).then(rps => {
      
      setExames(rps.data.obj);
      setCaProcessa(false);
    })
  }

  function abrirLaudo(laudo){
    setCaProcessa(true);

    var row = JSON.parse(JSON.stringify(laudo));
    var laudo;
    laudo.ATENRO = row.ATENRO;
    laudo.PACCOD = row.PACCOD;
    
    Api.post('laudos/abrir', laudo).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        window.open(rps.data.obj, "Aguarde... Carregando!", "");
        getListar();
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }
   
    return (
        <>
        <div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-6 mb-2 mr-5">Painel</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-6 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}          
          </div>
        </div>
     
       
      {/*begin::Entry*/}
      <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
      {/*begin::Row*/}
      <div className="row">

              {/*Inicio do Painel*/}
              <div class="col-md-3 col-sm-12">
                <LoadingOverlay
                  active={caPainel}
                  spinner
                  text='Carregando...'
                >
                {/*begin::Tiles Widget 12*/}
                <div className="card card-custom gutter-b" style={{height: '120px'}}>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="text-dark font-weight-bolder font-size-h2 mt-3">{countExames}</div>
                        <span className="text-success font-weight-bold font-size-lg mt-1">
                          <Link to="exames">Exame(s) Realizado(s)</Link>
                        </span>
                      </div>
                      <div className="col-md-4">
                        <span className="svg-icon svg-icon-6x svg-icon-success">
                          {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Group.svg*/}
                          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <rect x="0" y="0" width="24" height="24"/>
                              <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>
                              <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>
                              <rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1"/>
                              <rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1"/>
                            </g>
                          </svg>
                          {/*end::Svg Icon*/}
                        </span>
                      </div>
                    </div>
                    
                    
                  </div>
                </div>
                {/*end::Tiles Widget 12*/}
              {/*Fim Painel*/}
              </LoadingOverlay>
            </div>
                
              </div>
              {/*end::Row*/}

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >                 
                <DataTable
                  title="Exame(s) Realizado(s) - Últimos 30 dias"
                  columns={columns}
                  data={data}
                  striped="true" 
                  
                />
              </LoadingOverlay>      

              {/*end::Dashboard*/}
            </div>
            {/*end::Container*/}
          </div>
          {/*end::Entry*/}
        
        {/*end::Content*/}
        {/*begin::Footer*/}
        <div className="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
          {/*begin::Container*/}
          <div className="container d-flex flex-column flex-md-row align-items-center justify-content-between">
            {/*begin::Copyright*/}
            <div className="text-dark order-2 order-md-1">
              <span className="text-muted font-weight-bold mr-2">2024©</span>
              <a href="#" target="_blank" className="text-dark-75 text-hover-primary">LAC</a>
            </div>
            {/*end::Copyright*/}
            {/*begin::Nav*/}
            <div className="nav nav-dark order-1 order-md-2">
              {/*Aqui Link e informação a direita */}
            </div>
            {/*end::Nav*/}
          </div>
          {/*end::Container*/}
        </div>
        {/*end::Footer*/}
        {/*end::Wrapper*/}
        {/*end::Page*/}
        {/*end::Main*/}      
        </>
    );

}

export default withRouter(Painel);                  