import React, { useState, useEffect } from 'react';
import Api from '../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';
import Axios from 'axios';
import Moment from 'react-moment';

import DataTable from 'react-data-table-component';

import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

function dateFormatBr(date) {
  let data = new Date(date);
  let dataFormatada = (format_two_digits(data.getDate())) + "/" + 
                        (format_two_digits(data.getMonth()+1)) + "/" + 
                        (format_two_digits(data.getFullYear()));
  return dataFormatada;
}

function format_two_digits(n) {
  return n < 10 ? '0' + n : n;
}

const Exame = () => {
  const { addToast } = useToasts();
  const refProgramacao = React.useRef(null);
  const [ evento, setEventos ] = useState([]);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ modal, setModal ] = useState(false);
  const [ formLaudo, setFormLaudo ] = useState({});
  const [ dataInicialBusca, setDataInicialBusca ] = useState();
  const [ dataFinalBusca, setDataFinalBusca ] = useState();

  const data = evento;
  const columns = [
    {
      name: <th>Paciente</th>,
      selector: 'PACNOM',
      sortable: true,
      wrap: true,
      width: '28%',
    },
    {
      name: <th>Médico</th>,
      selector: 'MESNOM',
      width: '28%',
      sortable: true,
    },
    {
      name: <th>Data</th>,
      selector: 'ATEDAT',
      width: '12%',
      type: 'date',
      sortable: true,
      cell: (row) => { return dateFormatBr(row.ATEDAT) } 
    },
    {
      name: <th>Nome do exame</th>,
      width: '20%',
      sortable: true,
      selector: 'SERDES',
    }, 
    {
      name: <th>Ações</th>,
      center: 'true',
      cell: (row) => 
      { return (<>
        <button className="btn btn-success btn-sm" onClick={e => { abrirLaudo(row) }}><i className="far fa-folder-open" />Abrir</button>
    </>)
      },
    },
  ];

   useEffect(() => {
    getListar();
  }, []);

  function getListar() { 
    setCaProcessa(true); 
    var data = {
      pac: localStorage.getItem('id_usuario'),
    };
  
    Api.post('laudos/listar', data).then(rps => {
      
      setEventos(rps.data.obj);
      setCaProcessa(false);
    })
  }
 
  function abrirLaudo(laudo){
    setCaProcessa(true);

    var row = JSON.parse(JSON.stringify(laudo));
    var laudo;
    laudo.ATENRO = row.ATENRO;
    laudo.PACCOD = row.PACCOD;
    
    Api.post('laudos/abrir', laudo).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        window.open(rps.data.obj, "Aguarde... Carregando!", "");
        getListar();
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setCaProcessa(false);
    });
  }

  function filtrar() {
    setCaProcessa(true); 
      var data = {
        pac: localStorage.getItem('id_usuario'),
        dt_inicial: dataInicialBusca,
        dt_final: dataFinalBusca,
      };
    
      Api.post('laudos/filtrar', data).then((rps) => {

        if(rps.data.status==false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setEventos(rps.data.obj);
        }
      setCaProcessa(false); 
    });
  }
    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2 mt-5">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Exames Realizados</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">

                <div className="form-group col-md-3">
                  <label>Data Inicial</label>
                  <div className="input-group">
                      <DatePicker 
                        name="dt_inicio"
                        onChange={setDataInicialBusca}
                        isClearable
                        selectsStart
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="dd/mm/aaaa" 
                        selected ={dataInicialBusca}
                        dateFormat="dd/MM/y"
                        startDate={dataInicialBusca}
                        endDate={dataFinalBusca}
                        customInput={
                          <InputMask mask="99/99/9999" />}/>
                      </div>
                </div>

                <div className="form-group col-md-3">
                  <label>Data Final</label>
                  <div className="input-group">
                      <DatePicker 
                        name="dt_final"
                        onChange={setDataFinalBusca}
                        isClearable
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="dd/mm/aaaa" 
                        selected ={dataFinalBusca}
                        dateFormat="dd/MM/y"
                        selectsEnd
                        startDate={dataInicialBusca}
                        endDate={dataFinalBusca}
                        minDate={dataInicialBusca} 
                        customInput={
                          <InputMask mask="99/99/9999" />}/>
                      </div>
                </div>

                  <div className="col-md-2 form-group">
                  <label>&nbsp;</label><br />
                    <button className="btn btn-primary btn-block" onClick={e=>{filtrar()}}><i className="flaticon2-search-1" />Pesquisar</button>
                  </div> 
                </div> 
              </div>

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >                 
                <DataTable
                  title="Lista de Exames Realizados"
                  columns={columns}
                  data={data}
                  striped="true" 
                  pagination="true"
                />
              </LoadingOverlay>                    
      
                  </div>
                  </div>
                  </div>
                  </div>
                       
                 
        </>
    );
}

export default withRouter(Exame);                  