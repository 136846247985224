import React from 'react';
import Api from '../services/api';

import Header from './Header';
//import Sidebar from "react-sidebar";
import { Link } from 'react-router-dom';

const mql = window.matchMedia(`(min-width:  800px)`);

class index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: false,
      classe: '',
      menus: {},
      detalhesLoja: {},
      sidebarDocked: mql.matches,
      sidebarOpen: false
    }
    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }

  toggleCollapsed = () => {
    var classe = "";
    if(this.state.collapsed===true){
      classe =  "";
    } else {
      classe =  "aside-on";
    }

    this.setState({
      collapsed: !this.state.collapsed,
      classe: classe
    });
  };

  alternaMenu(item){
    var m = {};
    if(this.state.menus[item]===undefined || this.state.menus[item]===""){
      m[item] = "menu-item-open";
    } else {
      m[item] = "";
    }
    this.setState({
      ...this.state,
      menus: m,
    });
  }

  doLogout(){
    localStorage.clear();
  }
  
  componentWillMount() {
    mql.addListener(this.mediaQueryChanged);
  }
 
  componentWillUnmount() {
    //this.state.mql.removeListener(this.mediaQueryChanged);
  }
 
  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }
 
  mediaQueryChanged() {
    this.setState({ sidebarDocked: mql.matches, sidebarOpen: false });
  }

  render() {
    const { children } = this.props;

    return (
      <>
        <div className="d-flex flex-column flex-root">
          <div className="d-flex flex-row flex-column-fluid page">
         
            {/** INICIO DO ASIDE */}
            {this.state.classe==='aside-on' && 
            <div class="aside-overlay" onClick={e=> {this.toggleCollapsed()}}></div>
            }
            <div
              className={"aside aside-left d-flex flex-column flex-row-auto " + this.state.classe}
              id="kt_aside"
            >
              <div
                className="aside-menu-wrapper flex-column-fluid"
                id="kt_aside_menu_wrapper"
              >

                {/*begin::Menu Container*/}
                <div id="kt_aside_menu" className="aside-menu" data-menu-vertical={1} data-menu-scroll={1} data-menu-dropdown-timeout={500}>
                  {/*begin::Menu Nav*/}
                  <ul className="menu-nav">
                    <li className="menu-item" aria-haspopup="true">
                      <Link to="/painel" className="menu-link" onClick={e=>{this.toggleCollapsed()}}>
                        <span className="svg-icon menu-icon">
                          {/*begin::Svg Icon | path:assets/media/svg/icons/Design/Layers.svg*/}
                          <svg xmlns="https://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                              <rect x={0} y={0} width={24} height={24} />
                              <path d="M12,18 L7.91561963,20.1472858 C7.42677504,20.4042866 6.82214789,20.2163401 6.56514708,19.7274955 C6.46280801,19.5328351 6.42749334,19.309867 6.46467018,19.0931094 L7.24471742,14.545085 L3.94038429,11.3241562 C3.54490071,10.938655 3.5368084,10.3055417 3.92230962,9.91005817 C4.07581822,9.75257453 4.27696063,9.65008735 4.49459766,9.61846284 L9.06107374,8.95491503 L11.1032639,4.81698575 C11.3476862,4.32173209 11.9473121,4.11839309 12.4425657,4.36281539 C12.6397783,4.46014562 12.7994058,4.61977315 12.8967361,4.81698575 L14.9389263,8.95491503 L19.5054023,9.61846284 C20.0519472,9.69788046 20.4306287,10.2053233 20.351211,10.7518682 C20.3195865,10.9695052 20.2170993,11.1706476 20.0596157,11.3241562 L16.7552826,14.545085 L17.5353298,19.0931094 C17.6286908,19.6374458 17.263103,20.1544017 16.7187666,20.2477627 C16.5020089,20.2849396 16.2790408,20.2496249 16.0843804,20.1472858 L12,18 Z" fill="#000000"/>
                            </g>
                          </svg>
                          {/*end::Svg Icon*/}
                        </span>
                        <span className="menu-text">Principal</span>
                      </Link>
                    </li>
                    <li className="menu-section">
                      <h4 className="menu-text">Opções</h4>
                      <i className="menu-icon ki ki-bold-more-hor icon-md" />
                    </li>
                  
                    <li className="menu-item" aria-haspopup="true">
                      <Link to="/exames" className="menu-link" onClick={e=>{this.toggleCollapsed()}}>
                      <span className="svg-icon menu-icon">{/*begin::Svg Icon | path:C:\wamp64\www\keenthemes\themes\metronic\theme\html\demo5\dist/../src/media/svg/icons\General\Star.svg*/}<svg xmlns="https://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect x="0" y="0" width="24" height="24"/>
                          <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>
                          <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>
                          <rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1"/>
                          <rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1"/>
                        </g>
                        </svg>{/*end::Svg Icon*/}
                      </span>
                        <span className="menu-text">Exames Realizados</span>
                      </Link>
                    </li>

                    {/*
                    <li className="menu-item" aria-haspopup="true">
                      <Link to="/questionario/fatores" className="menu-link" onClick={e=>{this.toggleCollapsed()}}>
                      <span className="svg-icon menu-icon"><svg xmlns="https://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24" />
                          <path d="M13.6855025,18.7082217 C15.9113859,17.8189707 18.682885,17.2495635 22,17 C22,16.9325178 22,13.1012863 22,5.50630526 L21.9999762,5.50630526 C21.9999762,5.23017604 21.7761292,5.00632908 21.5,5.00632908 C21.4957817,5.00632908 21.4915635,5.00638247 21.4873465,5.00648922 C18.658231,5.07811173 15.8291155,5.74261533 13,7 C13,7.04449645 13,10.79246 13,18.2438906 L12.9999854,18.2438906 C12.9999854,18.520041 13.2238496,18.7439052 13.5,18.7439052 C13.5635398,18.7439052 13.6264972,18.7317946 13.6855025,18.7082217 Z" fill="#000000"/>
                          <path d="M10.3144829,18.7082217 C8.08859955,17.8189707 5.31710038,17.2495635 1.99998542,17 C1.99998542,16.9325178 1.99998542,13.1012863 1.99998542,5.50630526 L2.00000925,5.50630526 C2.00000925,5.23017604 2.22385621,5.00632908 2.49998542,5.00632908 C2.50420375,5.00632908 2.5084219,5.00638247 2.51263888,5.00648922 C5.34175439,5.07811173 8.17086991,5.74261533 10.9999854,7 C10.9999854,7.04449645 10.9999854,10.79246 10.9999854,18.2438906 L11,18.2438906 C11,18.520041 10.7761358,18.7439052 10.4999854,18.7439052 C10.4364457,18.7439052 10.3734882,18.7317946 10.3144829,18.7082217 Z" fill="#000000" opacity="0.3"/>
                        </g>
                        </svg>
                      </span>
                        <span className="menu-text">Fatores de Risco</span>
                      </Link>
                    </li>

                    <li className="menu-item" aria-haspopup="true">
                      <Link to="/questionarios/seguranca" className="menu-link" onClick={e=>{this.toggleCollapsed()}}>
                      <span className="svg-icon menu-icon"><svg xmlns="https://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <polygon points="0 0 24 0 24 24 0 24"/>
                          <path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                          <rect fill="#000000" x="6" y="11" width="9" height="2" rx="1"/>
                          <rect fill="#000000" x="6" y="15" width="5" height="2" rx="1"/>
                        </g>
                      </svg>
                      </span>
                        <span className="menu-text">Questionário 2</span>
                      </Link>
                    </li>
                  */}
                  <li className="menu-item mt-5" aria-haspopup="true">
                      <Link to="/login" onClick={e=>{this.doLogout()}} className="menu-link">
                        <span className="svg-icon menu-icon">
                          {/*begin::Svg Icon | path:assets/media/svg/icons/Design/Layers.svg*/}
                          <svg xmlns="https://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                              <rect x={0} y={0} width={24} height={24} />
                              <path d="M14.0069431,7.00607258 C13.4546584,7.00607258 13.0069431,6.55855153 13.0069431,6.00650634 C13.0069431,5.45446114 13.4546584,5.00694009 14.0069431,5.00694009 L15.0069431,5.00694009 C17.2160821,5.00694009 19.0069431,6.7970243 19.0069431,9.00520507 L19.0069431,15.001735 C19.0069431,17.2099158 17.2160821,19 15.0069431,19 L3.00694311,19 C0.797804106,19 -0.993056895,17.2099158 -0.993056895,15.001735 L-0.993056895,8.99826498 C-0.993056895,6.7900842 0.797804106,5 3.00694311,5 L4.00694793,5 C4.55923268,5 5.00694793,5.44752105 5.00694793,5.99956624 C5.00694793,6.55161144 4.55923268,6.99913249 4.00694793,6.99913249 L3.00694311,6.99913249 C1.90237361,6.99913249 1.00694311,7.89417459 1.00694311,8.99826498 L1.00694311,15.001735 C1.00694311,16.1058254 1.90237361,17.0008675 3.00694311,17.0008675 L15.0069431,17.0008675 C16.1115126,17.0008675 17.0069431,16.1058254 17.0069431,15.001735 L17.0069431,9.00520507 C17.0069431,7.90111468 16.1115126,7.00607258 15.0069431,7.00607258 L14.0069431,7.00607258 Z" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(9.006943, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-9.006943, -12.000000) " />
                              <rect fill="#000000" opacity="0.3" transform="translate(14.000000, 12.000000) rotate(-270.000000) translate(-14.000000, -12.000000) " x={13} y={6} width={2} height={12} rx={1} />
                              <path d="M21.7928932,9.79289322 C22.1834175,9.40236893 22.8165825,9.40236893 23.2071068,9.79289322 C23.5976311,10.1834175 23.5976311,10.8165825 23.2071068,11.2071068 L20.2071068,14.2071068 C19.8165825,14.5976311 19.1834175,14.5976311 18.7928932,14.2071068 L15.7928932,11.2071068 C15.4023689,10.8165825 15.4023689,10.1834175 15.7928932,9.79289322 C16.1834175,9.40236893 16.8165825,9.40236893 17.2071068,9.79289322 L19.5,12.0857864 L21.7928932,9.79289322 Z" fill="#000000" fillRule="nonzero" transform="translate(19.500000, 12.000000) rotate(-90.000000) translate(-19.500000, -12.000000) " />
                            </g>
                          </svg>
                          {/*end::Svg Icon*/}
                        </span>
                        <span className="menu-text">Sair</span>
                      </Link>
                    </li>

                    <li className="menu-item" aria-haspopup="true">
                      <span className="menu-text text-align-center py-5 px-9 text-muted">Versão: 2.0.1</span>
                    </li>
                  </ul>
                  {/*end::Menu Nav*/}
                </div>
                {/*end::Menu Container*/}



              </div>
            </div>
            {/** FIM DO ASIDE */}
            <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper" >
              
              {/** INICIO HEADER */}
              <Header testeLucas={this.toggleCollapsed} storeDetails={this.getDetalhesLoja}/>
              {/** FIM HEADER */}
              
              {/** Conteudo */}
              {children}
              {/** FIM Conteudo */}

              
            </div>
          </div>
        </div>

    

      </>
    )
  }
};
export default index;